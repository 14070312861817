import React from 'react'
import { css } from 'emotion'
import Helmet from 'react-helmet'

import { linkedIn, siteName } from '../data'

import Layout from '../components/layout'
import BtnLink from '../components/small/btn-link'
import Heading from '../components/small/heading'
import ContactIcon from '../components/small/contact-icon'

const breakPoint = 800

const sectionWrap = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakPoint}px) {
    flex-direction: row;
  }
`

const sectionStyles = css`
  @media (max-width: ${breakPoint - 1}px) {
    margin-bottom: 30px;
  }

  @media (min-width: ${breakPoint}px) {
    width: calc(50% - 10px);
  }
`

const ContactDeets = () => {
  const cont = css`
    display: flex;
    flex-wrap: wrap;

    > a {
      margin-bottom: 20px;

      @media (max-width: 600px) {
        min-width: 180px;
      }

      @media (min-width: 601px) {
        margin-right: 20px;
      }

      @media (min-width: ${breakPoint}px) {
        flex-basis: 150px;
      }
    }
  `
  return (
    <ul className={cont}>
      <ContactIcon type="email" text="true" />
      <ContactIcon type="skype" text="true" />
      <ContactIcon type="linkedin" text="true" />
      <ContactIcon type="phone" text="true" />
    </ul>
  )
}

const ServicesPage = () => (
  <Layout>
    <Helmet title={`Experience - ${siteName}`} />
    <div className={sectionWrap}>
      <section className={sectionStyles}>
        <Heading>Experience</Heading>
        <p>
          Caroline Chetwood trained with a top 4 international accountancy
          practice in London, where she went on to specialise in UK and foreign
          tax.
        </p>
        <p>
          Over 25 years of experience in all aspects of tax and business advice,
          including over 10 years with top London practices.
        </p>
        <p>Both a qualified chartered accountant and chartered tax adviser.</p>
        <p>
          <BtnLink to={linkedIn} external={true}>
            See more on LinkedIn
          </BtnLink>
        </p>
      </section>

      <section className={sectionStyles}>
        <Heading>Get in touch</Heading>
        <p>
          If you have any questions or would like to book a free initial
          consultation, get in touch via the following:
        </p>
        <ContactDeets />
      </section>
    </div>
  </Layout>
)

export default ServicesPage
